import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import { Link } from 'gatsby';
import c from 'classnames';
import { Col, Container, Row } from 'react-bootstrap';
import * as styles from './footer.module.scss';
import { StaticQuery, graphql } from 'gatsby';

import {
  FacebookCircleIcon,
  InstagramCircleIcon,
  LinkedinCircleIcon,
  PinterestCircleIcon,
  FooterLogoIcon
} from '../../images/index';

const Footer = () => {
  const intl = useIntl();

  return (
    <StaticQuery
      query={graphql`
        query FooterTemplate {
          markdownRemark(fileAbsolutePath: { regex: "/footer.md/" }) {
            frontmatter {
              hu {
                email
                facebook
                instagram
                linkedin
                pinterest
              }
            }
          }
        }
      `}
      render={(data) => (
        <Container fluid className={c(styles.footerContainer, 'p-3')}>
          <Row className="p-3">
            <Col md={4} className={styles.logoColumn}>
              <FooterLogoIcon />
            </Col>
            <Col md={4} className="text-center">
              <a>
                <h4>{intl.formatMessage({ id: 'main.footer.contact' })}</h4>
              </a>
              <p>
                <a
                  href={`mailto:${data.markdownRemark.frontmatter.hu.email}`}
                  className={styles.email}
                >
                  {data.markdownRemark.frontmatter.hu.email}
                </a>
              </p>

              <p className="ml-1">
                <a
                  title="facebook"
                  href={data.markdownRemark.frontmatter.hu.facebook}
                >
                  <FacebookCircleIcon className={styles.socialIcon} />
                </a>
                <a
                  title="instagram"
                  href={data.markdownRemark.frontmatter.hu.instagram}
                >
                  <InstagramCircleIcon className={styles.socialIcon} />
                </a>
                <a
                  title="pinterest"
                  href={data.markdownRemark.frontmatter.hu.pinterest}
                >
                  <PinterestCircleIcon className={styles.socialIcon} />
                </a>

                <a
                  title="linkedin"
                  href={data.markdownRemark.frontmatter.hu.linkedin}
                >
                  <LinkedinCircleIcon className={styles.socialIcon} />
                </a>
              </p>
            </Col>
            <Col md={4} className={styles.columns}>
              <p>
                <Link to="/impressum" className={styles.footerLink}>
                  {intl.formatMessage({ id: 'main.footer.impressum' })}
                </Link>
              </p>
              <p>
                <Link to="/terms" className={styles.footerLink}>
                  {intl.formatMessage({ id: 'main.footer.terms' })}
                </Link>
              </p>
              <p>
                <Link to="/privacy" className={styles.footerLink}>
                  {intl.formatMessage({ id: 'main.footer.privacy' })}
                </Link>
              </p>
            </Col>
          </Row>
        </Container>
      )}
    />
  );
};

export default Footer;
