import React from 'react';
import { Nav, NavDropdown, Button } from 'react-bootstrap';
import { changeLocale, useIntl } from 'gatsby-plugin-intl';
import c from 'classnames';
import * as styles from './navbar.module.scss';

const NavMenu = () => {
  const intl = useIntl();

  return (
    <Nav className="ml-auto">
      <Nav.Item className={styles.i18nContainer}>
        <NavDropdown
          title={intl.formatMessage({ id: 'main.nav.enterior' })}
          id="basic-nav-dropdown"
          className={c(styles.dropDown, 'mr-4')}
        >
          <NavDropdown.Item href="/enterior">
            {intl.formatMessage({ id: 'main.nav.enterior' })}
          </NavDropdown.Item>
          <NavDropdown.Item href="/enterior/services">
            - {intl.formatMessage({ id: 'main.nav.services' })}
          </NavDropdown.Item>
          <NavDropdown.Item href="/enterior/portfolio">
            - {intl.formatMessage({ id: 'main.nav.portfolio' })}
          </NavDropdown.Item>
        </NavDropdown>
        <NavDropdown
          title={intl.formatMessage({ id: 'main.nav.products' })}
          id="basic-nav-dropdown"
          className={c(styles.dropDown, 'mr-4')}
        >
          <NavDropdown.Item href="/products/">
            {intl.formatMessage({ id: 'main.nav.products' })}
          </NavDropdown.Item>
          <NavDropdown.Item href="/products">
            - {intl.formatMessage({ id: 'main.nav.wood' })}
          </NavDropdown.Item>
          <NavDropdown.Item href="/products">
            - {intl.formatMessage({ id: 'main.nav.concrete' })}
          </NavDropdown.Item>
          <NavDropdown.Item href="/products">
            - {intl.formatMessage({ id: 'main.nav.sustainable' })}
          </NavDropdown.Item>
        </NavDropdown>
        <Nav.Link href="/collaboration" eventKey="link-1" className="mr-4">
          {intl.formatMessage({ id: 'main.nav.cooperation' })}
        </Nav.Link>
        <Nav.Link href="/about" eventKey="link-2" className="mr-4">
          {intl.formatMessage({ id: 'main.nav.about' })}
        </Nav.Link>
        <Nav.Link href="/contact" eventKey="link-3" className="mr-4">
          {intl.formatMessage({ id: 'main.nav.contact' })}
        </Nav.Link>
        <Nav.Item className="ml-auto">
          <Button
            className="ml-auto"
            variant="link"
            onClick={() => {
              intl.locale === 'hu' ? changeLocale('en') : changeLocale('hu');
            }}
          >
            {intl.locale === 'hu' ? 'EN' : 'HU'}
          </Button>
        </Nav.Item>
      </Nav.Item>
    </Nav>
  );
};

export default NavMenu;
