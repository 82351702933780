import { injectIntl } from 'gatsby-plugin-intl';
import { StaticQuery, graphql } from 'gatsby';
import { useIntl } from 'gatsby-plugin-intl';
import Navbar from './Navbar/Navbar';
import Footer from './Footer/Footer';
import SEO from './SEO/SEO';
import WebRights from './WebRights/Rights';
import React from 'react';
import CookieConsent from 'react-cookie-consent';

const TemplateWrapper = ({ children }) => {
  const intl = useIntl();
  const cookieConsentText =
    intl.locale === 'hu'
      ? 'A weboldal sütik formájában információkat tárol az Ön böngészésre használt eszközén. Ezek az információk személyes adatnak minősülhetnek. Ezek a cookiekban tárolt információk vonatkozhatnak az Ön böngészési szokásaira, a használt eszközökre, böngészőkre, tárolt adatokra, a weboldal többletfunkcióira (pl. felhasználóbarát élmény). Azonban a weboldal működéséhez nem minden cookie elengedhetetlenül szükséges. Ezeket a többletfunkcióval bíró cookiekat kizárólag az Ön hozzájárulásával alkalmazzuk, így Önnek lehetősége nyílik arra, hogy eldöntse, a weboldalon mely cookiek alkalmazását engedélyezi számunkra. Tájékoztatjuk, hogy amennyiben Ön úgy dönt, hogy egyes cookiekat nem engedélyez, a böngészési élmény megváltozhat, bizonyos tartalmakhoz korlátozott lesz az Ön hozzáférése. Bővebb tájékoztatást a weboldalon alkalmazott cookiekról a Honlap adatvédelmi tájékoztatóban talál.'
      : 'The website stores information in the form of cookies on your browsing device. This information may be considered personal information. This information stored in cookies may relate to your browsing habits, devices used, browsers, data stored, and additional features of the website (eg user-friendly experience). However, not all cookies are essential for the operation of the website. We use these cookies with additional functionality only with your consent, so you have the opportunity to decide which cookies you allow us to use on the website. Please note that if you choose not to allow certain cookies, your browsing experience may change and your access to certain content will be restricted. You can find more information about the cookies used on the website in the Privacy Statement.';

  return (
    <StaticQuery
      query={graphql`
        query seoTemplate {
          markdownRemark(fileAbsolutePath: { regex: "/general.md/" }) {
            frontmatter {
              en {
                seoTagList {
                  seoTagKey
                  seoTagValue
                }
                siteTitle
                siteDescription
              }
              hu {
                seoTagList {
                  seoTagKey
                  seoTagValue
                }
                siteTitle
                siteDescription
              }
            }
          }
        }
      `}
      render={(data) => (
        <>
          <SEO seoData={data} />
          <Navbar />
          <>{children}</>
          <Footer />
          <WebRights />
          <CookieConsent
            location="bottom"
            buttonText={intl.locale === 'hu' ? 'Elfogadom' : 'Accept'}
            declineButtonText={intl.locale === 'hu' ? 'Elutasítom' : 'Decline'}
            cookieName="gatsby-gdpr-google-analytics"
            enableDeclineButton
            buttonStyle={{
              background: '#fff',
              borderRadius: '4px',
              fontFamily: 'Montserrat',
              fontWeight: 700,
              padding: '11px 33px',
              margin: '0px 15px 10px 0px'
            }}
            declineButtonStyle={{
              background: '#000',
              borderRadius: '4px',
              fontFamily: 'Montserrat',
              fontWeight: 700,
              padding: '11px 33px',
              margin: '0px 15px 10px 15px'
            }}
          >
            {cookieConsentText}
          </CookieConsent>
        </>
      )}
    />
  );
};

export default injectIntl(TemplateWrapper);
