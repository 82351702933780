import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import { Helmet } from 'react-helmet';

const SEO = ({ seoData }) => {
  const { frontmatter } = seoData.markdownRemark;
  const intl = useIntl();
  const langData = intl.locale === 'hu' ? frontmatter.hu : frontmatter.en;

  return (
    <Helmet>
      <html lang={intl.locale} />
      <title>{langData.siteTitle}</title>
      <meta name="description" content={langData.siteDescription} />
      {langData.seoTagList &&
        langData.seoTagList.map((seo, index) => (
          <meta name={seo.seoTagKey} content={seo.seoTagValue} key={index} />
        ))}
    </Helmet>
  );
};

export default SEO;
