import React, { useState } from 'react';
import { Navbar } from 'react-bootstrap';
import NavMenu from './NavMenu';
import c from 'classnames';
import * as styles from './navbar.module.scss';
import { CloseIcon, LogoIcon } from '../../images/index';

const CustomNavbar = () => {
  const [isClicked, setIsClicked] = useState(false);
  const showCloseIcon = () => setIsClicked(!isClicked);

  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      bg="white"
      variant="light"
      sticky="top"
    >
      <Navbar.Brand href="/">
        <LogoIcon />
      </Navbar.Brand>
      <Navbar.Toggle
        aria-controls="responsive-navbar-nav"
        onClick={showCloseIcon}
      >
        {isClicked && <CloseIcon />}
      </Navbar.Toggle>
      <Navbar.Collapse
        className={c(styles.mobileNav, 'justify-content-end mw-100')}
        id="responsive-navbar-nav"
      >
        <NavMenu />
      </Navbar.Collapse>
      <div
        className={styles.customMobileNav}
        style={{ left: isClicked ? '0' : '-100%' }}
      >
        <NavMenu />
      </div>
    </Navbar>
  );
};

export default CustomNavbar;
