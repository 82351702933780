import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import * as styles from './rights.module.scss';

const WebRights = () => {
  return (
    <Container fluid className={styles.container}>
      <Row>
        <Col className="text-center">
          <p className={styles.webRight}>
            © 2021 - {new Date().getFullYear()} Niké Interior Design
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default WebRights;
